<template>
  <div class="smartDTContainer">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>
      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          应用开发服务
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="ptDesc center">
        <div class="ptDescContent">
          <div class="ptDescLeft">
            <img src="@/assets/product/dataCenter/dataPt.png" alt="" style="width:450px;height:100%;border-radius: 10px 0 0 10px;">
          </div>
          <div class="ptDescRigth">
            易普行拥有专业化的应用开发服务团队，长期为客户提供端到端的应用软件开发和维护服务。凭借在CMMI和ISO9001质量标准和流程认证基础上形成的系统化、成熟的方法论，以及多年来的丰富项目实践经验，易普行能够为客户提供包括：系统开发、平台开发、APP开发、项目定制等全栈服务，提升业务效率、大幅的降低开发成本，为客户提供更高的IT投资回报。
          </div>
        </div>

        <h4 class="descH4">服务优势</h4>
        <span class="line"></span>
        <div class="valueContent">
          <div class="valueItem" v-for="(item,index) in valueList" :key="index">
            <div class="valueItemMask"></div>
            <img :src="item.src" alt="" class="valueLeft">
            <img :src="item.hoverScr" alt="" class="valueLeftHover">
            <div class="valueTitle">{{item.title}}</div>
            <div class="valueRight">
              {{item.content}}
            </div>
          </div>
        </div>
      </div>

      <Foot></Foot>
    </div>

    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'ProductYykffw',
  data () {
    return {
      valueList: [
        { title: '全覆盖的信息解决方案', content: '易普行提供高校、医院、研究院等事业单位覆盖全人力资源管理的解决方案。', src: require('@/assets/Cooperation/ecological/open.png'), hoverScr: require('@/assets/product/productPt/jgzx.png') },
        { title: '全链全生命周期开发服务', content: '涵盖开发流程中的各个阶段，包括：需求分析、设计、编码、测试、部署以及实施后的支持。', src: require('@/assets/product/dataCenter/shujufs.png'), hoverScr: require('@/assets/product/dataCenter/sjjc.png') },
        { title: '多样化的定制开发服务', content: '易普行凭借多年的IT行业积累，为客户提供多样化、专业化的定制化开发服务。', src: require('@/assets/product/productPt/agile.png'), hoverScr: require('@/assets/product/productPt/mjbh.png') }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moProduct/moProductYykffw')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
.smartDTContainer{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}
.channelShip {
  padding-top: 80px;
}
.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/product/ProductYykffw/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}

.ptDesc{
  width: 100%;
height: 880px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}
.ptDescContent{
  width: 1200px;
  height: 300px;
  display: flex;
  align-items: center;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.3);
  margin: 40px 0;
  border-radius: 10px;
}
.ptDescLeft{
  position: relative;
  top: 0;
  left: 0;
  width: 450px;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.ptDescLeftMask{
  width: 450px;
  height: 100%;
  border-radius: 10px 0 0 10px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(45, 45, 48, 0.15);
}
.ptDescRigth{
  width: 750px;
  height: 100%;
  background: url('~@/assets/product/productPt/ptBj.jpg') center no-repeat;
  background-size: 100% 100%;
  border-radius: 0px 10px 10px 0px;
  padding: 50px 40px;
  text-shadow: 0px 8px 24px rgba(0,0,0,0.15);
  font-size: 18px;
  color: #222;
  font-weight: 400;
  line-height: 40px;
}

.systemValue{
     width: 100%;
height: 490px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.valueContent{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  width: 386px;
  height: 250px;
  margin-right: 21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 0 20px;
  cursor: pointer;
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:last-child{
  margin-right: 0px;
}
.valueLeft{
  width: 68px;
}
.valueLeftHover{
  width: 68px;
  display: none;
}
.valueLine{
  width: 30px;
  height: 4px;
  background: #D70C0C;
  margin: 14px 0 20px;
}
.valueTitle{
  font-weight: 500;
  font-size: 24px;
  color: #222222;
  line-height: 36px;
  text-align: center;
  font-style: normal;
  margin:20px 0;
}
.valueRight{
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 24px;
  text-align: center;
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueRight{
    color: #fff;
  }
  .valueTitle{
    color: #fff;
  }
  .valueLine{
    background-color: #fff;
  }
  .valueLeftHover{
    display: block;
  }
  .valueLeft{
    display: none;
  }
}

</style>
